<template>
  <EmailLoader>
    <p>
      We are proud to include you to our team!
    </p>

    <div class="text-center">
      <b-button variant="primary">
        Visit Dashboard
      </b-button>
    </div>
  </EmailLoader>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import EmailLoader from '../setup/loader.vue'

export default {
  components: {
    BButton,
    EmailLoader,
  },
}
</script>
